import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-idea-dtw',
  templateUrl: './idea-dtw.component.html',
  styleUrls: ['./idea-dtw.component.scss']
})
export class IdeaDtwComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
