<div id="impressum">
    <h1 class="title">Impressum</h1>
    <h2>
        Hochschule Trier, Standort Birkenfeld <br>
        Hochschule für Wirtschaft, Technik und Gestaltung
    </h2>
    <p>
        Campusallee<br>
        D-55768 Hoppstädten-Weiersbach<br>
        <br>
        Telefon: +49 6782 17-0<br>
        Telefax: +49 6782 17-13 17<br>
        <br>
        <a href="mailto:info@umwelt-campus.de">info[at]umwelt-campus.de</a><br>
        <br>
        Die Hochschule Trier, Standort Birkenfeld, ist eine Körperschaft des öffentlichen Rechts. Sie wird durch die
        Präsidentin Prof. Dr. Dorit Schumann gesetzlich vertreten.<br>
    </p>
    <h2>Zuständige Aufsichtsbehörde</h2>
    <p>
        Ministerium für Ministerium für Wissenschaft, Weiterbildung und Kultur des Landes Rheinland-Pfalz<br>
        Mittlere Bleiche 61<br>
        D-55116 Mainz<br>
    </p>
    <h2>Inhaltlich Verantwortlich gemäß §5 TMG</h2>
    <p>
        Rüdiger Machhamer: <a href="mailto:r.machhamer@umwelt-campus.de">r.machhamer@umwelt-campus.de</a>
    </p>
    <h2>Haftungshinweis</h2>
    <p>
        Für die innerhalb des WWW-Angebots der Hochschule Trier, Standort Birkenfeld, zugänglichen Seiten besteht
        keinerlei Gewährleistung. Es wird auch keine Haftung für die Korrektheit, Vollständigkeit, oder Anwendbarkeit
        der enthaltenen Informationen übernommen.
        <br>
        Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den
        Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
    </p>
</div>