import { Component, Input, OnInit } from '@angular/core';
import { CourseSlideTitle, ItemEntry } from 'src/app/interfaces/courses.interface';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss']
})
export class CourseComponent implements OnInit {

  @Input()
  courseEntries: ItemEntry<CourseSlideTitle>[] = [];

  @Input()
  activeIndex = 0;

  constructor() { }

  ngOnInit(): void {
  }

}
