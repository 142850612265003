import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import * as p5 from 'p5';
import { Calculation, DTWStages } from 'src/app/interfaces/dtw.interface';
import { DTW } from 'src/app/utils/dtw.utils';

@Component({
    selector: 'app-dtw-demo',
    templateUrl: './dtw-demo.component.html',
    styleUrls: ['./dtw-demo.component.scss']
})
export class DTWDemoComponent implements OnInit, AfterViewInit {

    DTW = DTW;
    DTWStages = DTWStages;

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        const width = event.target.innerWidth;
    }

    @ViewChild("interaction")
    interactionRef: ElementRef<HTMLCanvasElement> | undefined = undefined;

    @ViewChild("solveSpeedSlider")
    solveSpeedSlider: ElementRef<HTMLInputElement>;

    rawSeriesA = "";
    rawSeriesB = "";

    seriesA = [0, 0, 2, 3, 4, 3, 2, 0];
    seriesB = [0, 2, 3, 4, 3, 2, 0, 0];
    matrix = [];
    path = [];
    warpedConnections = [];

    sizeA = 0;
    sizeB = 0;

    width = 1118;
    height = 450;

    xStep = 50;

    window = 10;

    currentPosition = {
        x: undefined,
        y: undefined
    };

    nextPosition = {
        x: undefined,
        y: undefined
    };

    solveSpeed = 5;

    solveInterval;

    constructor() {
    }

    ngOnInit(): void {
        this.rawSeriesA = this.seriesA.join(",");
        this.rawSeriesB = this.seriesB.join(",");
    }

    ngAfterViewInit(): void {
        // this.width = this.interactionRef.nativeElement.getBoundingClientRect().width;
        // this.height = this.interactionRef.nativeElement.getBoundingClientRect().height;

        this.createSketch();
    }

    initializeMatrix(): void {
        const validity = /^[0-9]+(,[0-9]+)*$/g;

        if (!(this.rawSeriesA.match(validity) && this.rawSeriesB.match(validity))) {
            alert("Bitte nur Zahlen > 0 und mit einem Komma getrennt. Bsp: '1,2,3,4'")
            return;
        }

        this.seriesA = this.rawSeriesA.split(",").map(item => Number(item))
        this.seriesB = this.rawSeriesB.split(",").map(item => Number(item))

        this.sizeA = this.seriesA.length;
        this.sizeB = this.seriesB.length;

        if (this.sizeA > 15 || this.sizeB > 15 || this.sizeA < 4 || this.sizeB < 4) {
            alert("Die länge einer Series muss <= 15 und >=4 sein sein");
            return;
        }

        this.path = [];
        this.warpedConnections = [];
        this.currentPosition = {
            x: undefined,
            y: undefined
        };
        this.nextPosition = {
            x: undefined,
            y: undefined
        };
        clearInterval(this.solveInterval);
        this.solveInterval = null;
        DTW.resetSteps();
        DTW.currentCalculation = undefined
        this.matrix = DTW.generateInitialMatrix(this.sizeA, this.sizeB);
    }

    setSolveSpeed(event: InputEvent): void {
        this.solveSpeed = Number((event.target as HTMLInputElement).value);

        clearInterval(this.solveInterval)
        this.solveInterval = null;
    }

    private currentStepIs = (stepPrefix) => {
        return Object.keys(DTWStages).filter(element => element.includes(stepPrefix)).map(dtwItem => DTWStages[dtwItem]).includes(DTW.currentStage)
    }

    nextStepClick(): void {
        clearInterval(this.solveInterval);
        this.solveInterval = null;
        this.nextStep();
    }

    nextStep(): void {
        if (this.currentStepIs("DTW")) {
            this.solveMatrixStep();
        } else if (this.currentStepIs("PATH")) {
            this.generatePathStep();
        }
    }

    solveMatrixStep(): void {

        if (DTW.currentStage === DTWStages.DTW_FINISHED) {
            DTW.currentStage = DTWStages.PATH_STARTING
            return;
        }

        this.path = [];

        const distance = (a, b) => Math.abs(a - b);

        this.currentPosition = this.nextPosition;

        this.nextPosition = DTW.solveStep(this.matrix, this.seriesA, this.seriesB, distance, this.currentPosition)
    }

    solveMatrix(): void {
        this.initializeMatrix()

        const distance = (a, b) => Math.abs(a - b);

        DTW.solve(this.matrix, this.seriesA, this.seriesB, this.window, distance);

        DTW.currentStage = DTWStages.DTW_FINISHED;
        this.currentPosition = {
            x: this.sizeA - 1,
            y: this.sizeB - 1
        }
    }

    startAutoSolve(): void {
        if (this.solveSpeed === 10) {
            if (this.currentStepIs("DTW")) {
                this.solveMatrix();
                this.nextStep();
            } else if (this.currentStepIs("PATH")) {
                this.generatePath();
            }

            return;
        }

        if (!this.matrix.length) {
            this.initializeMatrix();
        }

        if (this.solveInterval) {
            clearInterval(this.solveInterval)
            this.solveInterval = null;
            return;
        }

        const map = (value, in_min, in_max, out_min, out_max) => {
            return (value - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
        }

        this.solveInterval = setInterval(() => {
            this.nextStep();
            if (DTW.currentStage === DTWStages.DTW_FINISHED || DTW.currentStage === DTWStages.PATH_FINISHED) {
                this.nextStep();
                clearInterval(this.solveInterval);
                this.solveInterval = null;
            }
        }, map(this.solveSpeed, 1, 9, 500, 10));
    }

    generatePathStep(): void {
        this.warpedConnections = [];

        
        if (DTW.currentStage === DTWStages.PATH_FINISHED) {
            this.generateWarpedConnections();
            return;
        }

        if (DTW.currentStage === DTWStages.DTW_FINISHED) {
            DTW.currentStage = DTWStages.PATH_STARTING;
            return;
        }

        this.currentPosition = DTW.generatePathStep(this.matrix, this.seriesA, this.seriesB, this.currentPosition);
        this.path = DTW.path;
    }

    generatePath(): void {
        this.warpedConnections = [];
        this.path = DTW.generatePath(this.matrix, this.seriesA, this.seriesB);
        this.currentPosition = { x: 0, y: 0 };
        DTW.currentStage = DTWStages.PATH_FINISHED;
        this.generateWarpedConnections();
    }

    generateWarpedConnections(): void {
        if (!this.path.length) {
            return;
        }
        this.warpedConnections = this.path.reduce((r, a) => {
            r[a.x] = r[a.x] || [];
            r[a.x].push(a);
            return r;
        }, []);
    }

    getMaxY(): number {
        return Math.max(...this.seriesA, ...this.seriesB)
    }

    getMinY(): number {
        return Math.min(...this.seriesA, ...this.seriesB)
    }

    createSketch(): void {
        const sketchSeries = (_: p5) => {

            let codeImageMatrix;
            let codeImagePath;

            let seriesOffsetY = 10;
            let seriesOffsetX = 30;
            let seriesAreaWidth = this.width / 4 - seriesOffsetY;
            let seriesAreaSegmentHeight = this.height / 5;

            let codeImageWidth = this.width / 2.25;
            let codeImageHeight = this.height;

            let matrixAreaWidth = this.width - seriesAreaWidth - codeImageWidth;

            _.preload = () => {
                codeImageMatrix = _.loadImage("../../../../../assets/graphics/code.png")
                codeImagePath = _.loadImage("../../../../../assets/graphics/code_path.png")
            }

            _.setup = () => {
                _.createCanvas(this.width, this.height);
            };

            _.draw = () => {
                _.background(255);

                const minY = this.getMinY();
                const maxY = this.getMaxY();

                {
                    _.push();
                    _.translate(0, seriesAreaSegmentHeight * 2)

                    _.stroke("black");
                    _.strokeWeight(4);

                    _.line(0, 0, seriesAreaWidth, 0);
                    _.line(0, -seriesAreaSegmentHeight - seriesOffsetY, seriesAreaWidth, -seriesAreaSegmentHeight - seriesOffsetY)

                    {
                        _.push()
                        _.translate(seriesOffsetX, 0)

                        // Draw grid
                        _.line(0, 0, 0, -this.height)

                        for (let j = minY; j <= maxY; j++) {
                            if (j === 0) continue;
                            const y = _.map(j, minY, maxY, 0, -seriesAreaSegmentHeight + seriesOffsetY * 2);
                            _.noStroke()
                            _.fill(0);
                            _.textAlign(_.CENTER, _.CENTER);
                            _.textSize(16)
                            _.text(j, -seriesOffsetY, y);

                            _.stroke(0);
                            _.strokeWeight(1);
                            _.line(0, y, seriesAreaWidth - seriesOffsetX, y);
                        }


                        {
                            _.push()
                            _.translate(0, -seriesOffsetY * 3);

                            for (let j = maxY + 1; j <= maxY * 2; j++) {
                                if (j === 0) continue;
                                const y = _.map(j, minY, maxY, 0, -seriesAreaSegmentHeight + seriesOffsetY * 2);
                                _.noStroke()
                                _.fill(0);
                                _.textAlign(_.CENTER, _.CENTER);
                                _.textSize(16)
                                _.text(j - maxY, -seriesOffsetY, y);

                                _.stroke(0);
                                _.strokeWeight(1);
                                _.line(0, y, seriesAreaWidth - seriesOffsetX, y);
                            }

                            _.pop()
                        }

                        _.noFill()
                        _.strokeWeight(4)

                        // Draw Series
                        this.xStep = (seriesAreaWidth - seriesOffsetX) / Math.max(this.seriesA.length, this.seriesB.length);

                        _.beginShape()
                        _.stroke("blue")
                        for (let i = 0; i < this.sizeA; i++) {
                            _.vertex(i * this.xStep, _.map(this.seriesA[i], minY, maxY, 0, -seriesAreaSegmentHeight + seriesOffsetY * 2));
                        }
                        _.endShape()

                        _.translate(0, -seriesOffsetY);

                        _.beginShape()
                        _.stroke("red")
                        for (let i = 0; i < this.sizeB; i++) {
                            _.vertex(i * this.xStep, _.map(this.seriesB[i], minY, maxY, -seriesAreaSegmentHeight, -seriesAreaSegmentHeight * 2 + seriesOffsetY * 2));
                        }
                        _.endShape();

                        _.pop()
                    }

                    _.line(seriesAreaWidth, 0, seriesAreaWidth, -this.height);
                    _.pop()
                }

                _.push();
                _.translate(this.width - codeImageWidth, 0);

                const xOffset = 10;
                let codeLineHeight = 16.75;
                let yOffset = 16.5;
                let correctedCodeWidth = codeImageWidth;


                switch (DTW.currentStage) {
                    case DTWStages.DTW_FILL_FIRST_COL:
                    case DTWStages.DTW_FILL_FIRST_ROW:
                    case DTWStages.DTW_FILL_MATRIX:
                    case DTWStages.DTW_FINISHED:
                    case DTWStages.DTW_STARTING: {
                        _.image(codeImageMatrix, 0, 0, correctedCodeWidth, codeImageHeight);
                        break;
                    }
                    default: {
                        correctedCodeWidth = codeImageWidth - 150;

                        _.image(codeImagePath, 0, 0, correctedCodeWidth, codeImageHeight);
                        codeLineHeight = 12.55
                        yOffset = 12;
                    }
                }

                _.strokeWeight(2);
                _.noFill();
                _.stroke("cyan");

                let lineIndex = 0;
                let lineSpan = 1;

                switch (DTW.currentStage) {
                    case DTWStages.DTW_STARTING: {
                        lineIndex = 1;
                        lineSpan = 1;
                        break;
                    }
                    case DTWStages.DTW_FILL_FIRST_ROW: {
                        lineIndex = 6;
                        lineSpan = 3;
                        break;
                    }
                    case DTWStages.DTW_FILL_FIRST_COL: {
                        lineIndex = 10;
                        lineSpan = 3;
                        break;
                    }
                    case DTWStages.DTW_FILL_MATRIX: {
                        lineIndex = 14;
                        lineSpan = 8
                        break;
                    }
                    case DTWStages.DTW_FINISHED: {
                        lineIndex = 23;
                        lineSpan = 1;
                        break;
                    }

                    case DTWStages.PATH_STARTING: {
                        lineIndex = 3;
                        lineSpan = 4;
                        break;
                    }
                    case DTWStages.PATH_CHECK_WHILE: {
                        lineIndex = 8;
                        lineSpan = 1;
                        break;
                    }
                    case DTWStages.PATH_CHECK_NO_BORDER: {
                        lineIndex = 9;
                        lineSpan = 1;
                        break;
                    }
                    case DTWStages.PATH_CALCULATE_MIN_DISTANCE: {
                        lineIndex = 10;
                        lineSpan = 4;
                        break;
                    }
                    case DTWStages.PATH_CHECK_TOP_BORDER: {
                        lineIndex = 22;
                        lineSpan = 1;
                        break;
                    }
                    case DTWStages.PATH_STEP_TOP_BORDER: {
                        lineIndex = 23;
                        lineSpan = 1;
                        break;
                    }
                    case DTWStages.PATH_CHECK_LEFT_BORDER: {
                        lineIndex = 24;
                        lineSpan = 1;
                        break;
                    }
                    case DTWStages.PATH_STEP_LEFT_BORDER: {
                        lineIndex = 25;
                        lineSpan = 1;
                        break;
                    }
                    case DTWStages.PATH_PUSH: {
                        lineIndex = 28;
                        lineSpan = 1;
                        break;
                    }
                    case DTWStages.PATH_STEP_CHECK_DIAGONAL: {
                        lineIndex = 14;
                        lineSpan = 1;
                        break;
                    }
                    case DTWStages.PATH_STEP_DIAGONAL: {
                        lineIndex = 15;
                        lineSpan = 2;
                        break;
                    }
                    case DTWStages.PATH_STEP_CHECK_LEFT: {
                        lineIndex = 17;
                        lineSpan = 1;
                        break;
                    }
                    case DTWStages.PATH_STEP_LEFT: {
                        lineIndex = 18;
                        lineSpan = 1;
                        break;
                    }
                    case DTWStages.PATH_STEP_CHECK_TOP: {
                        lineIndex = 19;
                        lineSpan = 1;
                        break;
                    }
                    case DTWStages.PATH_STEP_TOP: {
                        lineIndex = 20;
                        lineSpan = 1;
                        break;
                    }
                    case DTWStages.PATH_FINISHED: {
                        lineIndex = 31;
                        lineSpan = 2;
                        break;
                    }
                }

                _.rect(xOffset, yOffset + lineIndex * codeLineHeight, correctedCodeWidth - xOffset * 2, lineSpan * codeLineHeight)
                _.pop();


                if (!this.matrix.length) return;
                const cellSize = seriesAreaSegmentHeight * 3 / Math.max(this.sizeA + 1, this.sizeB + 1);


                _.push();
                _.translate(seriesAreaWidth, 0);

                const textLineXOffset = 12;
                const textLineHeight = 16;

                _.textSize(16)
                _.textAlign('left', "top")
                _.text("Aktuelle Indizes:", textLineXOffset, textLineHeight);

                let indexText = "";
                if (this.currentPosition.x !== undefined) {
                    indexText += `x: ${this.currentPosition.x}`
                };
                if (this.currentPosition.y !== undefined) {
                    indexText += `\ny: ${this.currentPosition.y}`
                };
                _.text(indexText || "Noch nicht verwendet", textLineXOffset * 2, textLineHeight * 2);

                _.text("Aktuelle Algorithmenstufe:", textLineXOffset, textLineHeight * 5);
                _.text(DTW.currentStage, textLineXOffset * 2, textLineHeight * 6);


                _.push()
                _.translate(- (seriesAreaWidth - (this.sizeB + 1) * cellSize), seriesAreaSegmentHeight * 1.5)
                _.textSize(16)
                _.textAlign('left', "top")

                const textWidth = this.width - codeImageWidth - (this.sizeB + 1) * cellSize - textLineXOffset;

                if (DTW.currentCalculation) {

                    let currentCalculationString = "";
                    _.text("Letzte Berechnung:", textLineXOffset, 0 + 8);
                    if (DTW.currentCalculation.raw) {
                        currentCalculationString = currentCalculationString.concat(DTW.currentCalculation.raw);
                    }
                    if (DTW.currentCalculation.computet) {
                        currentCalculationString = currentCalculationString.concat("\n->\n", DTW.currentCalculation.computet);
                    }
                    if (DTW.currentCalculation.simplified) {
                        currentCalculationString = currentCalculationString.concat("\n->\n", DTW.currentCalculation.simplified)
                    }
                    if (DTW.currentCalculation.result !== undefined) {
                        currentCalculationString = currentCalculationString.concat("\n\n= ", String(DTW.currentCalculation.result))
                    }

                    _.text(currentCalculationString, textLineXOffset, textLineHeight + 16, textWidth);
                }


                _.pop();

                _.pop();

                _.push()
                _.translate(0, seriesAreaSegmentHeight * 2)
                // Draw Matrix Headers
                for (let i = 1; i <= this.sizeA; i++) {
                    const x = i * cellSize;
                    _.stroke(0);
                    _.strokeWeight(1);
                    _.fill("blue");
                    _.rect(x, 0, cellSize, cellSize);

                    _.noStroke()
                    _.fill(0);
                    _.textAlign(_.CENTER, _.CENTER);
                    _.textSize(20 - this.seriesA[i - 1].toString().length)
                    _.text(this.seriesA[i - 1], x + cellSize / 2, cellSize / 2)
                }

                for (let j = 1; j <= this.sizeB; j++) {
                    const y = j * cellSize;
                    _.stroke(0);
                    _.strokeWeight(1);
                    _.fill("red");
                    _.rect(0, y, cellSize, cellSize);

                    _.noStroke()
                    _.fill(0);
                    _.textAlign(_.CENTER, _.CENTER);
                    _.textSize(20 - this.seriesB[j - 1].toString().length)
                    _.text(this.seriesB[j - 1], cellSize / 2, y + cellSize / 2)
                }

                _.push();
                _.translate(cellSize, cellSize)

                // Draw Matrix
                for (let i = 0; i < this.sizeA; i++) {
                    for (let j = 0; j < this.sizeB; j++) {
                        const x = i * cellSize;
                        const y = j * cellSize;
                        _.stroke(0);
                        _.strokeWeight(1);
                        _.noFill();
                        _.rect(x, y, cellSize, cellSize);

                        _.noStroke()
                        _.fill(0);
                        _.textAlign(_.CENTER, _.CENTER);
                        _.textSize(20 - this.matrix[i][j].toString().length)
                        const cellText = this.matrix[i][j].toString() === "Infinity" ? "∞" : this.matrix[i][j];
                        _.text(cellText, x + cellSize / 2, y + cellSize / 2)
                    }
                }
                _.push()
                _.noFill()
                _.stroke(0, 255, 0)
                _.strokeWeight(4)
                _.rect((this.currentPosition.x || 0) * cellSize, (this.currentPosition.y || 0) * cellSize, cellSize, cellSize)
                _.pop();


                //Draw Path
                _.noFill();
                _.stroke(0, 255, 0)
                _.strokeWeight(4)
                this.path.forEach(cell => {
                    _.rect(cell.x * cellSize, cell.y * cellSize, cellSize, cellSize)
                });
                _.pop();

                _.pop();


                if (!this.warpedConnections.length) return;

                _.translate(seriesOffsetX, seriesAreaSegmentHeight * 2 - seriesOffsetY)

                //Draw warped connections
                _.push()
                _.noFill();
                _.stroke(_.color(255, 150, 0, 255));
                _.strokeWeight(2);

                this.warpedConnections.forEach(connection => {
                    connection.forEach(({ x, y }) => {
                        _.line(
                            x * this.xStep,
                            _.map(
                                this.seriesA[x],
                                minY,
                                maxY,
                                seriesOffsetY,
                                -seriesAreaSegmentHeight + seriesOffsetY * 3),
                            y * this.xStep,
                            _.map(
                                this.seriesB[y],
                                minY,
                                maxY,
                                -seriesAreaSegmentHeight,
                                -seriesAreaSegmentHeight * 2 + seriesOffsetY * 2
                            )
                        );
                    })
                });
                _.pop()
            };
        }

        new p5(sketchSeries, this.interactionRef.nativeElement);
    }
}
