<div id="header">
    <div routerLink="/home" class="project-title">DTW/DBA</div>
    <div class="burger-menu">
        <div class="indicator" (click)="toggleBurgerMenu()">
            <div class="bars" [class.open]="isMenuOpen">
                <div class="bar1"></div>
                <div class="bar2"></div>
                <div class="bar3"></div>
            </div>
        </div>
        <div *ngIf="isMenuOpen" class="overlay" (click)="toggleBurgerMenu()"></div>
        <div class="menu" [class.visible]="isMenuOpen">
            <div (click)="navigate(item)" *ngFor="let item of headerItems" class="item">
                <div class="item-content">
                    {{(item.index || item.index === 0) ? item.index+1 + " - " : ""}} {{item.title}}
                </div>
            </div>
        </div>
    </div>
</div>