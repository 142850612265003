<div id="course-step">
    <div class="interactive">
        <div class="course-section interaction" #interaction></div>
        <div class="course-section settings">
            <p>
                Hier kannst du durch den gesamten Algorithmus durchlaufen
            </p>
            <div class="input">
                <div class="initialize">
                    <div class="inputs">
                        <div class="seriesA">
                            <label for="seriesA">Serie A</label>
                            <input type="text" [(ngModel)]="rawSeriesA" pattern="^[0-9]+(,[0-9]+)*$" name="seriesA"
                                id="seriesA" disabled>
                        </div>
                        <div class="seriesB">
                            <label for="seriesB">Serie B</label>
                            <input type="text" [(ngModel)]="rawSeriesB" pattern="^[0-9]+(,[0-9]+)*$" name="seriesB"
                                id="seriesB" disabled>
                        </div>
                    </div>
                </div>
                <div class="control">
                    <button (click)="initializeMatrix()">Initialisiere Matrix</button>
                    <div class="slider">
                        <label for="solve-speed">Lösungsgeschwindigkeit</label>
                        <input type="range" name="solve-speed" id="solve-speed" min="1" max="10" step="1"
                            [(ngModel)]="solveSpeed" (input)="setSolveSpeed($event)" #solveSpeedSlider>
                    </div>
                    <button (click)="startAutoSolve()">Start/Stop</button>
                    <button (click)="nextStepClick()" [disabled]="!matrix.length">Nächster Schritt</button>
                </div>
            </div>
        </div>
    </div>
</div>