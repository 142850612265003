<div id="course-progression">
    <div class="navigation">
        <div class="start">
            <div class="circle"></div>
        </div>

        <div class="seperation"></div>

        <ng-container *ngFor="let courseEntry of courseEntries; let i = index">
            <div class="progression-item"
                 [class.active]="i === activeIndex"
                 [class.passed]="i < activeIndex"
                 (click)="emitNewIndex(i)">
                <div class="item-title">
                    {{courseEntry.title}}
                </div>
                <div class="item-index">
                    <div class="index">{{i+1}}</div>
                </div>
            </div>

            <div class="seperation"></div>
        </ng-container>

        <div class="end">
            <div class="circle"></div>
        </div>
    </div>
</div>