import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CourseSlideTitle, ItemEntry } from "../../interfaces/courses.interface";

@Component({
  selector: 'app-course-progression',
  templateUrl: './course-progression.component.html',
  styleUrls: ['./course-progression.component.scss']
})
export class CourseProgressionComponent implements OnInit {

  @Input()
  courseEntries: ItemEntry<CourseSlideTitle>[] = [];

  @Input()
  activeIndex: number = 0;

  @Output()
  emitIndexChange: EventEmitter<number> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  emitNewIndex(index: number): void {
    this.emitIndexChange.emit(index)
  }

}
