import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QuizItem } from 'src/app/interfaces/quiz.interface';

@Component({
  selector: 'app-quiz-item',
  templateUrl: './quiz-item.component.html',
  styleUrls: ['./quiz-item.component.scss']
})
export class QuizItemComponent implements OnInit {

  @Input()
  item: QuizItem;

  @Input()
  index: number;

  @Input()
  group: number = 1;

  @Input()
  disabled?: boolean = false;

  @Input()
  evaluate?: boolean = false;

  @Output()
  emitSelected: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
  }

  selectItem(index: number): void {
    this.emitSelected.emit(index);
  }

}
