import { Component, Input, OnInit } from '@angular/core';
import { QuizItem } from 'src/app/interfaces/quiz.interface';

@Component({
  selector: 'app-quiz-result',
  templateUrl: './quiz-result.component.html',
  styleUrls: ['./quiz-result.component.scss']
})
export class QuizResultComponent implements OnInit {

  @Input()
  items: QuizItem[];

  correct = 0;
  amount = 0;

  constructor() { }

  ngOnInit(): void {
    this.correct = this.items.reduce((prev, curr) => prev + Number(curr.selected === curr.correctOption), 0);
    this.amount = this.items.length;
  }

}
