import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/views/home/home.component';
import { CourseContainerComponent } from './components/views/course-container/course-container.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { CourseComponent } from './components/course/course.component';
import { CourseProgressionComponent } from './components/course-progression/course-progression.component';
import { ImpressumComponent } from './components/views/impressum/impressum.component';
import { EvaluationComponent } from './components/views/evaluation/evaluation.component';
import { IntroductionComponent } from './components/course-steps/introduction-dtw/introduction-dtw.component';
import { IdeaDtwComponent } from './components/course-steps/idea-dtw/idea-dtw.component';
import { DTWDemoComponent } from './components/course-steps/dtw-demo/dtw-demo.component';
import { FormsModule } from '@angular/forms';
import { DbaDemoComponent } from './components/course-steps/dba-demo/dba-demo.component';
import { QuizComponent } from './components/course-steps/quiz/quiz.component';
import { QuizItemComponent } from './components/quiz-item/quiz-item.component';
import { QuizResultComponent } from './components/quiz-result/quiz-result.component';
import { CheckmarkComponent } from './components/checkmark/checkmark.component';
import { XMarkComponent } from './components/x-mark/x-mark.component';
import { IntroductionDbaComponent } from './components/course-steps/introduction-dba/introduction-dba.component';
import { IdeaDbaComponent } from './components/course-steps/idea-dba/idea-dba.component';
import { ScrollHintComponent } from './scroll-hint/scroll-hint.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CourseContainerComponent,
    HeaderComponent,
    FooterComponent,
    CourseComponent,
    CourseProgressionComponent,
    ImpressumComponent,
    EvaluationComponent,
    IntroductionComponent,
    IdeaDtwComponent,
    DTWDemoComponent,
    DbaDemoComponent,
    QuizComponent,
    QuizItemComponent,
    QuizResultComponent,
    CheckmarkComponent,
    XMarkComponent,
    IntroductionDbaComponent,
    IdeaDbaComponent,
    ScrollHintComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
