import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { courseEntries } from 'src/app/interfaces/courses.interface';

@Component({
  selector: 'app-course-container',
  templateUrl: './course-container.component.html',
  styleUrls: ['./course-container.component.scss']
})
export class CourseContainerComponent implements OnInit {

  readonly courseEntries = courseEntries;

  activeIndex = 0;

  constructor(private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (!params.slide) {
        return;
      }
      this.activeIndex = Number(params.slide)
    });
  }

  updateActiveIndex(index: number): void {
    if (index === this.courseEntries.length) {
      this.router.navigate(["/evaluation"]);
      return;
    }
    this.activeIndex = index;
    this.router.navigate([], { queryParams: { slide: index } })
  }

}
