import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ItemEntry, CourseSlideTitle } from '../../interfaces/courses.interface';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @Input()
  courseEntries: ItemEntry<CourseSlideTitle>[] = [];

  @Input()
  activeIndex = 0;

  @Output()
  emitActiveIndexChange: EventEmitter<number> = new EventEmitter();


  constructor() { }

  ngOnInit(): void {
  }

  updateItemIndex(index: number): void {
    this.emitActiveIndexChange.emit(index);
  }

  goBack(): void {
    window.scrollTo(0, 0)
    this.emitActiveIndexChange.emit(this.activeIndex - 1);
  }

  goForth(): void {
    window.scrollTo(0, 0)
    this.emitActiveIndexChange.emit(this.activeIndex + 1);
  }

}
