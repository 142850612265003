<div id="course-step">
    <div class="description side-by-side">
        <div class="course-section description">
            <h2>Was ist Dynamic Time Warp?</h2>

            <p>
                DTW (Dynamic Time Warping) ist ein Algorithmus, mit dem man zwei Zeitserien unterschiedlicher Länge
                vergleichen kann.
            </p>

            <p>
                Das am weitesten verbreitete Anwendungsbeispiel ist die Spracherkennung. Ein Wort kann unterschiedlich
                schnell gesprochen werden und dadurch kommt es vor das die Länge des gesprochenen Wortes nicht mit der
                Länge des gleichen Wortes, was schneller oder
                langsamer gesprochen wurde, übereinstimmt. Um eben diese beiden Zeitserien miteinander zu vergleichen,
                wird der DTW Algorithmus verwendet. Der DTW Algorithmus liefert dann eine Distanz zurück und mit dieser
                Distanz kann man dann bewerten,
                wie identisch die Zeitserien sind.
            </p>

            <p>
                Dieser DTW-Algorithmus wird auf den folgenden Seiten genauer erklärt und visualisiert.
            </p>
        </div>
        <div class="course-section image">
            <img src="../../../../assets/graphics/warping.png" alt="Warped series">
        </div>
    </div>
</div>