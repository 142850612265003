import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { courseEntries, CourseSlideTitle, ItemEntry } from 'src/app/interfaces/courses.interface';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  headerItems: ItemEntry<CourseSlideTitle | string>[] = [
    {
      title: "Startseite",
      additional: "/home"
    },
    ...courseEntries,
    {
      title: "Evaluation",
      additional: "/evaluation"
    },
    {
      title: "Impressum",
      additional: "/impressum"
    },
    {
      title: "Datenschutz",
      additional: "https://www.umwelt-campus.de/datenschutz"
    },
  ]

  isMenuOpen = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  toggleBurgerMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  navigate(item: ItemEntry<CourseSlideTitle | string>): void {
    this.isMenuOpen = false;

    if (item.index || item.index === 0) {
      this.router.navigate(["/course"], { queryParams: { slide: item.index } })
      return
    }

    if (!item.additional) {
      return;
    }

    if (item.additional.includes("datenschutz")) {
      window.open(item.additional, "_blank")
      return;
    }

    this.router.navigate([item.additional]);
  }
}
