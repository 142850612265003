export interface QuizItem {
    question: string,
    options: string[],
    correctOption: number,
    selected?: number
}

export const quizItems =
    [
        {
            question: "Für was steht DTW",
            options: [
                "Dynamic Time Warning",
                "Dynamic Tomb Wrapping",
                "Dynamic Time Warping"
            ],
            correctOption: 2
        },
        {
            question: "Was ist DTW",
            options: [
                "Ein Algorithmus, mit dem den Durchschnitt mehrerer Zeitserien berechnen kann.",
                "Ein Algorithmus, mit dem man zwei Zeitserien unterschiedlicher Länge vergleichen kann.",
                "Ein Algorithmus, mit dem man Sprachen digitalisiert.",
            ],
            correctOption: 1
        },
        {
            question: "Wie wird die Matrix initialisiert?",
            options: [
                "Mit ∞.",
                "Mit 0.",
                "Keines von beiden."
            ],
            correctOption: 0
        },
        {
            question: "Wie wird die Distanz der einzelnen Punkte berechnet?",
            options: [
                "Mit der Distanz der beiden betrachteten Datenpunkte und der größten Distanz der benachbarten Vorgänger.",
                "Mit der Distanz der beiden betrachteten Datenpunkte.",
                "Mit der Distanz der beiden betrachteten Datenpunkte und der kleinsten Distanz der benachbarten Vorgänger.",
            ],
            correctOption: 2
        },
        {
            question: "Wie kommt man an die Distanz der beiden betrachteten Zeitserien?",
            options: [
                "Man liest sie im letzten Punkt der Matrix ab.",
                "Man addiert alle Einzeldistanzen zusammen.",
                "Man addiert alle Werte in der letzten Spalte mit allen Werten aus der letzten Zeile.",
            ],
            correctOption: 0
        },
        {
            question: "Wie wird der Warping-Pfad erstellt?",
            options: [
                "Durch Backtracking vom letzten Punkt zum ersten Punkt der Matrix, indem man immer den kleinsten Wert der drei Vorgänger nimmt",
                "Durch Backtracking vom letzten Punkt zum ersten Punkt der Matrix, indem man immer den größten Wert der drei Vorgänger nimmt",
                "Indem man einfach vom ersten Punkt der Matrix immer den kleinsten der drei Nachfolger nimmt bis man am Ende ist.",
            ],
            correctOption: 0
        },
        {
            question: "Die Distanz zweier Zeitserien mit dem DTW-Algorithmus ist schlechter als mit dem Euklidischen Algorithmus",
            options: [
                "Ja.",
                "Manchmal.",
                "Nein.",
            ],
            correctOption: 2
        },
        {
            question: "Für was steht DBA?",
            options: [
                "DTW Bernhard Averaging",
                "DTW Barycenter Averaging",
                "DTW Barycenter Algorithm",
            ],
            correctOption: 1
        },
        {
            question: "Man kann DBA auch ohne DTW durchführen.",
            options: [
                "Nein",
                "Ja",
                "Was ist DTW?",
            ],
            correctOption: 0
        },
        {
            question: "Durch was teilt man jeden Teilwert um am Ende eine neue gemittelte Serie zu erhalten?",
            options: [
                "Durch 2.",
                "Durch die Anzahl seines Auftretens.",
                "Durch die Summe der vorherigen Teilwerte",
            ],
            correctOption: 1
        },
    ]
