<div id="course-step">
    <div class="description">
        <div class="course-section">
            <h1>Vorstellung DBA-Algorithmus</h1>
            <div class="split">
                <div class="segment wording">
                    <p>
                        Um DBA zu berechnen, startet ein DBA-Schritt mit einer Startserie C (Initiale
                        Mittelwerts-Zeitserie) und mehreren Zeitserien S.
                    </p>
                    <p>
                        Am Anfang erstellt man 2 mit Nullen initialisierte Arrays. Im ersten speichert man die
                        summierten Teilwerte und im zweiten die Anzahl der summierten Teilwerte.
                    </p>
                </div>

                <div class="segment code">
                    <pre>
let updatedMean = new Array(C.length).fill(0);
let nElementsForMean = new Array(C.length).fill(0);
                    </pre>
                </div>
            </div>

            <app-scroll-hint></app-scroll-hint>
        </div>

        <div class="course-section">
            <div class="split">
                <div class="segment wording">
                    <p>
                        Man läuft durch alle Zeitserien durch und berechnet zu jeder dieser Serien den DTW mit der
                        Startserie und dem jeweils dazugehörigen Pfad.
                    </p>
                    <p>
                        Dann läuft man durch jeden dieser Pfade (in dem die x und y Positionen der Matrix erhalten sind)
                        und summiert zu jedem Teilwert an der Stelle x den Wert der aktuellen Zeitserie T an der Stelle
                        y und inkrementiert dann den Counter des Teilwertes.
                    </p>
                </div>

                <div class="segment code">
                    <pre>
for (const T of sequences) {{"{"}}
    DTW.solve(matrix, C, T, DistanceFunctions.SQUARED);

    const path = DTW.generatePath(matrix, C, T);

    for (const {{"{"}} x, y } of path) {{"{"}}
        updatedMean[x] += T[y]
        nElementsForMean[x]++;
    }
}
                    </pre>
                </div>
            </div>
        </div>

        <div class="course-section">
            <div class="split">
                <div class="segment wording">
                    <p>
                        Zum Schluss teilt man jeden Teilwert durch die Anzahl seines Auftretens und erhält damit die
                        neue gemittelte Serie.
                    </p>
                </div>

                <div class="segment code">
                    <pre>
for (let t = 0; t &lt; C.length; t++) {{"{"}}
    updatedMean[t] /= nElementsForMean[t];
}
                    </pre>
                </div>
            </div>
        </div>

        <div class="course-section">
            <div class="split">
                <div class="segment wording">
                    <h4>
                        Hier wird der Code nochmal im Detail erklärt
                    </h4>

                    <div class="segment code">
                        <pre>
// Wir erstellen eine Funktion die eine Serie mithilfe anderer Serien mitteln soll.
// C: Die aktuelle Serie die wir mitteln wollen
// sequences: Alle Serien mit deren Hilfe wir mitteln wollen
// matrix: Die Matrix mit deren Hilfe wir den Warping Pfad ermitteln
function DBAUpdate(C: number[], sequences: number[][], matrix: number[][]): number[] {{"{"}}
    // Man erstellt zwei Arrays mit der Länge der zu prüfenden Serie und initialisiert diese mit 0
    // In updatedMean speichert man die summierten Teilwerte
    let updatedMean = new Array(C.length).fill(0);
    // In nElementsForMean speichert man Anzahl der summierten Teilwerte
    let nElementsForMean = new Array(C.length).fill(0);

    // Man läuft durch jede Sequenz durch
    for (const T of sequences) {{"{"}}
        // Man löst zur aktuellen Sequenz T die Wariping-Matrix
        DTW.solve(matrix, C, T, DistanceFunctions.SQUARED);

        // Man erstellt den aktuellen Pfad
        const path = DTW.generatePath(matrix, C, T);

        // Man läuft durch den aktuellen Pfad und summiert zu jedem Teilwert
        // an der Stelle x den Wert der aktuellen Zeitserie T an der Stelle y
        // und inkrementiert dann den Counter des Teilwertes.
        for (const {{"{"}} x, y } of path) {{"{"}}
            updatedMean[x] += T[y]
            nElementsForMean[x]++;
        }
    }

    // Man teilt jeden Teilwert durch die Anzahl seines Auftretens
    // und erhält damit die neue gemittelte Serie.
    for (let t = 0; t &lt; C.length; t++) {{"{"}}
        updatedMean[t] /= nElementsForMean[t];
    }

    // Man gibt die mit gemittelte Serie zurück
    return updatedMean;
}
                    </pre>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>