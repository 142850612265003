import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-scroll-hint',
  templateUrl: './scroll-hint.component.html',
  styleUrls: ['./scroll-hint.component.scss']
})
export class ScrollHintComponent implements OnInit {

  @HostListener('window:scroll', ['$event'])
  setFade(event: MouseEvent): void {
    this.showScrollHint = window.pageYOffset < this.threshhold;
  }

  @Input()
  threshhold = 300;

  @Input()
  scrollDistance = 350;

  @Output()
  emitHintClicked = new EventEmitter<void>();

  showScrollHint = true;

  constructor() { }

  ngOnInit(): void {
  }

  emitClicked(): void {
    this.emitHintClicked.emit();
  }

  scrollDown(): void {
    window.scrollBy({
      top: this.scrollDistance,
      behavior: 'smooth'
    });
  }
}
