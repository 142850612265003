import { Component, OnInit } from '@angular/core';
import { QuizItem, quizItems } from 'src/app/interfaces/quiz.interface';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit {

  readonly quizItems: QuizItem[] = quizItems;

  currentIndex = 0;
  currentItem: QuizItem = undefined;

  score = 0;

  constructor() { }

  ngOnInit(): void {
    this.currentItem = this.quizItems[this.currentIndex];
  }

  setCurrentSelection(index: number): void {
    this.currentItem.selected = index
  }

  nextQuestion(): void {
    this.currentItem = this.quizItems[++this.currentIndex];
  }

  previousQuestion(): void {
    if (this.currentIndex === 0) {
      return;
    }
    this.currentItem = this.quizItems[--this.currentIndex];
  }

}
