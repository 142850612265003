<div id="evaluation">
    <h1>
        Vielen Dank für Ihre Teilnahme!
    </h1>
    <p>
        Wir bitten Sie ganz herzlich, diese kurze Umfrage zum Kurs auszufüllen, um uns dabei zu helfen, künftig noch bessere interaktive Kurse zu erstellen. Die Umfrage findet über die Lernplattform OpenOLAT statt. Sollten Sie keinen Zugang zur Plattform von
        Ihrer Hochschule haben, so nutzen Sie bitte unbedingt rechts den Reiter "Gastzugang". Vielen Dank.
    </p>
    <button (click)="routeToEvaluation()">
        Umfrage starten
    </button>
</div>