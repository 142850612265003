<div id="quiz">
    <div *ngIf="currentIndex !== quizItems.length" class="section title">
        <div class="block">
            <div class="headline">
                <h2>Quiz</h2>
                <h3>Was haben sie gelernt?</h3>
            </div>
            <div class="subline">
                Nun da wir wissen, wie eine DTW und DBA funktionieren, testen wir ihr Wissen mit einem Quiz. <br>
                Beantworten Sie zunächst die 10 Fragen. <br>
                Auf der nächsten Seite finden Sie die Auswertung des Quiz.
            </div>
        </div>
    </div>
    <div class="section quiz">
        <div *ngIf="currentIndex!==quizItems.length" class="block">
            <app-quiz-item [item]="currentItem" [index]="currentIndex+1" (emitSelected)="setCurrentSelection($event)">
            </app-quiz-item>
            <div class="buttons">
                <button [disabled]="currentIndex === 0" (click)="previousQuestion()" class="previous">
                    &lt; Vorherige Frage
                </button>
                <button [disabled]="currentItem.selected === undefined" (click)="nextQuestion()" class="next">
                    {{currentIndex === quizItems.length - 1 ? "Ergebnis" : "Nächste Frage &gt;"}}
                </button>
            </div>
        </div>

        <div *ngIf="currentIndex===quizItems.length" class="block quiz-result">
            <app-quiz-result [items]="quizItems"></app-quiz-result>
        </div>
    </div>
</div>