import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-evaluation',
  templateUrl: './evaluation.component.html',
  styleUrls: ['./evaluation.component.scss']
})
export class EvaluationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  routeToEvaluation(): void {
    window.open("https://olat.vcrp.de/auth/RepositoryEntry/3371500278/CourseNode/105936798706870", "_blank");
  }

}
