import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CourseContainerComponent } from './components/views/course-container/course-container.component';
import { EvaluationComponent } from './components/views/evaluation/evaluation.component';
import { HomeComponent } from './components/views/home/home.component';
import { ImpressumComponent } from './components/views/impressum/impressum.component';

const routes: Routes = [
  {
    path: "home", component: HomeComponent
  },
  {
    path: "course", component: CourseContainerComponent
  },
  {
    path: "impressum", component: ImpressumComponent
  },
  {
    path: "evaluation", component: EvaluationComponent
  },
  {
    path: "**", redirectTo: "/home"
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
