<div *ngIf="item" id="quiz-item">

    <div class="current">
        <div>
            <span class="question-index">Frage {{index}}:</span> {{item.question}}
        </div>
    </div>

    <div class="answers">
        <div *ngFor="let option of item.options; let i = index" class="option" (click)="selectItem(i)">
            <div>
                <input *ngIf="!disabled" type="radio" [name]="group" [id]="option"
                    [checked]="item.selected !== undefined && item.selected === i">
                <label [for]="option">{{option}}</label>
            </div>
            <span *ngIf="evaluate" class="result">
                <checkmark *ngIf="i === item.correctOption"></checkmark>
                <x-mark *ngIf="i !== item.correctOption && i === item.selected "></x-mark>
            </span>
        </div>
    </div>
</div>