import { Calculation, DistanceFunctions, DTWStages } from "../interfaces/dtw.interface";

export class DTW {

    static currentStage = DTWStages.DTW_STARTING;

    static currentCalculation: Calculation;

    static path = null;

    static generateInitialMatrix(sizeA: number, sizeB: number): number[][] {
        const matrix = [];
        for (let i = 0; i < sizeA; i++) {
            matrix[i] = [];
            for (let j = 0; j < sizeB; j++) {
                matrix[i][j] = Number.POSITIVE_INFINITY
            }
        }
        return matrix;
    }

    static solveStep(matrix: number[][],
        seriesA: number[],
        seriesB: number[],
        distance: (a: number, b: number) => number,
        currentPos: { x: number, y: number }): { x: number, y: number } {

        const sizeA = seriesA.length;
        const sizeB = seriesB.length;

        let x = currentPos.x;
        let y = currentPos.y;

        if (this.currentStage === DTWStages.DTW_STARTING) {
            matrix[0][0] = distance(seriesA[0], seriesB[0]);

            this.currentCalculation = {
                raw: "matrix[0][0] = distance(seriesA[0], seriesB[0])",
                computet: `matrix[0][0] = distance(seriesA[0], seriesB[0])`,
                simplified: `matrix[0][0] = distance(${seriesA[0]}, ${seriesB[0]})`,
                result: distance(seriesA[0], seriesB[0])
            }

            this.currentStage = DTWStages.DTW_FILL_FIRST_ROW;
            return { x: 1, y };
        }

        if (this.currentStage === DTWStages.DTW_FILL_FIRST_ROW) {
            if (x < sizeA) {
                var cost = distance(seriesA[x], seriesB[0]);
                matrix[x][0] = cost + matrix[x - 1][0];

                this.currentCalculation = {
                    raw: "matrix[x][0] = distance(seriesA[x], seriesB[0]) + matrix[x - 1][0];",
                    computet: `matrix[${x}][0] = distance(seriesA[${x}], seriesB[0]) + matrix[${x} - 1][0];`,
                    simplified: `matrix[${x}][0] = ${distance(seriesA[x], seriesB[0])} + ${matrix[x - 1][0]};`,
                    result: distance(seriesA[x], seriesB[0]) + matrix[x - 1][0]
                }

                x++;
            } else {
                this.currentStage = DTWStages.DTW_FILL_FIRST_COL;
                x = undefined;
                y = 1;
            }

            return { x, y }
        }

        if (this.currentStage === DTWStages.DTW_FILL_FIRST_COL) {
            if (y < sizeA) {
                cost = distance(seriesA[0], seriesB[y]);
                matrix[0][y] = cost + matrix[0][y - 1];

                this.currentCalculation = {
                    raw: "matrix[0][y] = distance(seriesA[0], seriesB[y]) + matrix[0][y - 1];",
                    computet: `matrix[0][${y}] = distance(seriesA[0], seriesB[${y}]) + matrix[0][${y} - 1];`,
                    simplified: `matrix[0][${y}] = ${distance(seriesA[0], seriesB[y])} + ${matrix[0][y - 1]};`,
                    result: distance(seriesA[0], seriesB[y]) + matrix[0][y - 1]
                }

                y++;
            } else {
                this.currentStage = DTWStages.DTW_FILL_MATRIX;
                x = 1;
                y = 1;
            }

            return { x, y };
        }

        if (this.currentStage === DTWStages.DTW_FILL_MATRIX) {
            if (y < sizeB) {
                var cost = distance(seriesA[x], seriesB[y]);
                matrix[x][y] =
                    cost + Math.min(
                        matrix[x - 1][y], // Insertion
                        matrix[x][y - 1], // Deletion
                        matrix[x - 1][y - 1]); // Match

                this.currentCalculation = {
                    raw: `matrix[x][y] = distance(seriesA[x], seriesB[y]) + Math.min(matrix[x - 1][y], matrix[x][y - 1], matrix[x - 1][y - 1]);`,
                    computet: `matrix[${x}][${y}] = distance(seriesA[${x}], seriesB[${y}]) + Math.min(matrix[${x} - 1][${y}], matrix[${x}][${y} - 1], matrix[${x} - 1][${y} - 1]); `,
                    simplified: `matrix[${x}][${y}] = ${distance(seriesA[x], seriesB[y])} + Math.min(${matrix[x - 1][y]}, ${matrix[x][y - 1]}, ${matrix[x - 1][y - 1]}); `,
                    result: distance(seriesA[x], seriesB[y]) +
                        Math.min(matrix[x - 1][y],
                            matrix[x][y - 1],
                            matrix[x - 1][y - 1])
                }

                y++;
                if (y === sizeB) {
                    y = 1;
                    x++;
                }
                if (x === sizeA && y === 1) {
                    this.currentStage = DTWStages.DTW_FINISHED;
                    return { x, y };
                }
                return { x, y };
            }
        }
    }

    static resetSteps(): void {
        this.currentStage = DTWStages.DTW_STARTING;
    }

    static solve(matrix: number[][], seriesA: number[], seriesB: number[], window: number, distance: (a: number, b: number) => number): number {
        matrix[0][0] = distance(seriesA[0], seriesB[0]);

        const sizeA = seriesA.length;
        const sizeB = seriesB.length;

        for (var x = 1; x < Math.min(sizeA, 1 + window); x++) {
            var cost = distance(seriesA[x], seriesB[0]);
            matrix[x][0] = cost + matrix[x - 1][0];
        }

        for (var y = 1; y < Math.min(sizeB, 1 + window); y++) {
            var cost = distance(seriesA[0], seriesB[y]);
            matrix[0][y] = cost + matrix[0][y - 1];
        }

        for (var x = 1; x < sizeA; x++) {
            const yStart = Math.max(1, x - window);
            const yStop = Math.min(sizeB, x + window + 1);
            const indexInftyLeft = x - window - 1;
            if (indexInftyLeft >= 0) matrix[x][indexInftyLeft] = Number.POSITIVE_INFINITY;
            for (var y = yStart; y < yStop; y++) {
                var cost = distance(seriesA[x], seriesB[y]);
                matrix[x][y] =
                    cost + Math.min(
                        matrix[x - 1][y], // Insertion
                        matrix[x][y - 1], // Deletion
                        matrix[x - 1][y - 1]); // Match
            }
        }

        return matrix[sizeA - 1][sizeB - 1];
    }

    static generatePathStep(matrix: number[][], seriesA: number[], seriesB: number[], currentPos: { x: number, y: number }): { x: number, y: number } {
        var x = currentPos.x;
        var y = currentPos.y;

        switch (this.currentStage) {
            case DTWStages.PATH_STARTING: {
                this.path = [];
                x = seriesA.length - 1;
                y = seriesB.length - 1;
                this.path.push({ x: x, y: y });

                this.currentStage = DTWStages.PATH_CHECK_WHILE
                return { x, y };
            }
            case DTWStages.PATH_CHECK_WHILE: {
                if ((x > 0) || (y > 0)) {
                    this.currentStage = DTWStages.PATH_CHECK_NO_BORDER;
                } else {
                    this.currentStage = DTWStages.PATH_FINISHED;
                }
                return { x, y };
            }
            case DTWStages.PATH_CHECK_NO_BORDER: {

                this.currentStage = DTWStages.PATH_CALCULATE_MIN_DISTANCE;

                this.currentCalculation = {
                    raw: "var min = Math.min(matrix[x - 1][y], matrix[x][y - 1], matrix[x - 1][y - 1]);",
                    computet: `var min = Math.min(matrix[${x} - 1][${y}], matrix[${x}][${y} - 1], matrix[${x} - 1][${y} - 1]);`,
                    simplified: `var min = Math.min(${matrix[x - 1][y]}, ${matrix[x][y - 1]}, ${matrix[x - 1][y - 1]});`,
                    result: Math.min(
                        matrix[x - 1][y],
                        matrix[x][y - 1],
                        matrix[x - 1][y - 1])
                }

                return { x, y };
            }
            case DTWStages.PATH_CALCULATE_MIN_DISTANCE: {
                if ((x > 0) && (y > 0)) {
                    this.currentStage = DTWStages.PATH_STEP_CHECK_DIAGONAL;
                }
                else if ((x > 0) && (y === 0)) {
                    this.currentStage = DTWStages.PATH_CHECK_TOP_BORDER;
                } else if ((x === 0) && (y > 0)) {
                    this.currentStage = DTWStages.PATH_CHECK_LEFT_BORDER;
                }

                return { x, y };
            }
            case DTWStages.PATH_STEP_CHECK_DIAGONAL: {
                var min = Math.min(
                    matrix[x - 1][y],
                    matrix[x][y - 1],
                    matrix[x - 1][y - 1]);

                if (min === matrix[x - 1][y - 1]) {
                    this.currentStage = DTWStages.PATH_STEP_DIAGONAL;
                } else {
                    this.currentStage = DTWStages.PATH_STEP_CHECK_LEFT;
                }

                return { x, y };
            }
            case DTWStages.PATH_STEP_DIAGONAL: {
                x--;
                y--;
                this.currentStage = DTWStages.PATH_PUSH;

                return { x, y };
            }
            case DTWStages.PATH_STEP_CHECK_LEFT: {
                var min = Math.min(
                    matrix[x - 1][y],
                    matrix[x][y - 1],
                    matrix[x - 1][y - 1]);

                if (min === matrix[x - 1][y]) {
                    this.currentStage = DTWStages.PATH_STEP_LEFT;
                } else {
                    this.currentStage = DTWStages.PATH_STEP_CHECK_TOP;
                }

                return { x, y };
            }
            case DTWStages.PATH_STEP_LEFT: {
                x--;
                this.currentStage = DTWStages.PATH_PUSH;

                return { x, y };
            }
            case DTWStages.PATH_STEP_CHECK_TOP: {
                var min = Math.min(
                    matrix[x - 1][y],
                    matrix[x][y - 1],
                    matrix[x - 1][y - 1]);

                if (min === matrix[x][y - 1]) {
                    this.currentStage = DTWStages.PATH_STEP_TOP;
                }

                return { x, y };
            }
            case DTWStages.PATH_STEP_TOP: {
                y--;
                this.currentStage = DTWStages.PATH_PUSH;

                return { x, y };
            }
            case DTWStages.PATH_CHECK_LEFT_BORDER: {
                if ((x === 0) && (y > 0)) {
                    this.currentStage = DTWStages.PATH_STEP_LEFT_BORDER;
                }

                return { x, y };
            }
            case DTWStages.PATH_STEP_LEFT_BORDER: {
                y--;
                this.currentStage = DTWStages.PATH_PUSH;

                return { x, y };
            }
            case DTWStages.PATH_CHECK_TOP_BORDER: {
                if ((x > 0) && (y === 0)) {
                    this.currentStage = DTWStages.PATH_STEP_TOP_BORDER;
                } else {
                    this.currentStage = DTWStages.PATH_CHECK_LEFT_BORDER;
                }

                return { x, y };
            }
            case DTWStages.PATH_STEP_TOP_BORDER: {
                x--;
                this.currentStage = DTWStages.PATH_PUSH;

                return { x, y };
            }
            case DTWStages.PATH_PUSH: {
                this.currentStage = DTWStages.PATH_CHECK_WHILE;
                this.path.push({ x, y });

                return { x, y };
            }
            case DTWStages.PATH_FINISHED: {

                return { x, y };
            }
        }
    }

    static generatePath(matrix: number[][], seriesA: number[], seriesB: number[]): { x: number, y: number }[] {
        const path = [];
        var cx = seriesA.length - 1;
        var cy = seriesB.length - 1;
        path.push({ x: cx, y: cy });
        while ((cx > 0) || (cy > 0)) {
            if ((cx > 0) && (cy > 0)) {
                var min = Math.min(
                    matrix[cx - 1][cy], // Insertion
                    matrix[cx][cy - 1], // Deletion
                    matrix[cx - 1][cy - 1]); // Match
                if (min === matrix[cx - 1][cy - 1]) {
                    cx--;
                    cy--;
                } else if (min === matrix[cx - 1][cy]) {
                    cx--;
                } else if (min === matrix[cx][cy - 1]) {
                    cy--;
                }
            } else if ((cx > 0) && (cy === 0)) {
                cx--;
            } else if ((cx === 0) && (cy > 0)) {
                cy--;
            }
            path.push({ x: cx, y: cy });
        }
        path.reverse()
        return path;
    }

    static DBAUpdate(C: number[], sequences: number[][], window: number, matrix: number[][]): number[] {
        let updatedMean = new Array<number>(C.length).fill(0);
        let nElementsForMean = new Array<number>(C.length).fill(0);

        for (const T of sequences) {
            DTW.solve(matrix, C, T, window, DistanceFunctions.SQUARED);

            const path = DTW.generatePath(matrix, C, T);

            for (const { x, y } of path) {
                updatedMean[x] += T[y]
                nElementsForMean[x]++;
            }
        }

        for (let t = 0; t < C.length; t++) {
            updatedMean[t] /= nElementsForMean[t];
        }

        return updatedMean;
    }
}