export const DistanceFunctions = {
    MANHATTAN: (a, b) => Math.abs(a - b),
    SQUARED: (a, b) => (a - b) * (a - b)
}

export enum DTWStages {
    DTW_STARTING = "Anfangen",
    DTW_FILL_FIRST_ROW = "Erste Reihe füllen",
    DTW_FILL_FIRST_COL = "Erste Zeile füllen",
    DTW_FILL_MATRIX = "Matrix befüllen",
    DTW_FINISHED = "Fertig",
    //
    PATH_STARTING =                      "Pfad initialisieren",
    PATH_CHECK_WHILE =                   "While prüfen",
    PATH_CHECK_NO_BORDER =               "Prüfen ob wir nicht am Rand sind",
    PATH_CALCULATE_MIN_DISTANCE =        "Geringste Distanz berechnen",
    PATH_STEP_CHECK_DIAGONAL =           "Diagonale Position prüfen",
    PATH_STEP_DIAGONAL =                 "Diagonal laufen",
    PATH_STEP_CHECK_LEFT =               "Linke Position prüfen",
    PATH_STEP_LEFT =                     "Nach links laufen",
    PATH_STEP_CHECK_TOP =                "Oberes Position prüfen",
    PATH_STEP_TOP =                      "Nach oben laufen",
    PATH_CHECK_LEFT_BORDER =             "Prüfen ob wir am linken Rand sind",
    PATH_STEP_LEFT_BORDER =              "Nach oben laufen",
    PATH_CHECK_TOP_BORDER =              "Prüfen ob wir am oberen Rand sind",
    PATH_STEP_TOP_BORDER =               "Nach links laufen",
    PATH_PUSH =                          "Element zum Pfad hinzufügen",
    PATH_FINISHED =                      "Pfad fertig",
}

export interface Calculation {
    raw: string,
    computet?: string,
    simplified?: string,
    result: number | string;
}