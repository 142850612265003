import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import * as p5 from 'p5';
import { DTW } from 'src/app/utils/dtw.utils';

const random = (min, max) => {
    return Math.random() * (max - min) + min;
}

@Component({
    selector: 'app-dba-demo',
    templateUrl: './dba-demo.component.html',
    styleUrls: ['./dba-demo.component.scss']
})
export class DbaDemoComponent implements OnInit, AfterViewInit {

    @ViewChild("sampleSizeSlider")
    sampleSizeSlider: ElementRef<HTMLInputElement>;

    @ViewChild("autoChangeCheckbox")
    autoChangeCheckbox: ElementRef<HTMLInputElement>;


    @ViewChild("interaction")
    interactionRef: ElementRef<HTMLCanvasElement> | undefined = undefined;

    center = [];
    matrix = [];

    readonly numItems = 100;

    showReference = false;
    showCenter = true;

    reference = new Array(this.numItems).fill(0).map((item, index) => Math.sin(index * 0.125));

    numVariations = 50;
    variations: number[][] = [];

    referenceSize = this.reference.length;
    step = 0;
    scale = 50;

    window = 10;

    width = 600;
    height = 400;

    wobbleInterval;

    constructor() {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.width = document.body.getBoundingClientRect().width < this.width ? document.body.getBoundingClientRect().width : this.width;

        this.createSketch();
    }

    initializeMatrix(): void {
        this.center = [];
        this.matrix = DTW.generateInitialMatrix(this.referenceSize, this.referenceSize);
    }

    dbaUpdate(): void {
        if (!this.matrix.length) return;

        this.center = new Array(this.numItems).fill(0)
        this.center = DTW.DBAUpdate(this.center, this.variations, this.window, this.matrix);
    }

    toggleAutoChange(): void {
        if (this.wobbleInterval) {
            clearInterval(this.wobbleInterval);
            this.wobbleInterval = undefined;
            this.autoChangeCheckbox.nativeElement.checked = false;
            return;
        }

        const map = (value, in_min, in_max, out_min, out_max) => {
            return (value - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
        }

        let i = 0;
        this.autoChangeCheckbox.nativeElement.checked = true;
        this.wobbleInterval = setInterval(() => {
            const newSampleSize = map(Math.sin(i += 0.25), -1, 1, 2, 100)

            this.setVariations(newSampleSize);
        }, 500);
    }

    setVariations(event: InputEvent | number): void {
        if (typeof event === "number") {
            this.numVariations = Math.floor(event);
        } else {
            this.numVariations = Number((event.target as HTMLInputElement).value);
            clearInterval(this.wobbleInterval)
            this.wobbleInterval = undefined;
            this.autoChangeCheckbox.nativeElement.checked = false;
        }

        this.sampleSizeSlider.nativeElement.value = `${this.numVariations}`

        this.variations = (new Array(this.numVariations)).fill([]).map(sequence => {
            const offset = random(-1, 1);
            return this.reference.map(value => value + offset + random(-1, 1))
        });

        this.initializeMatrix()
        this.dbaUpdate()
    }

    createSketch(): void {
        const sketch = (_: p5) => {
            _.setup = () => {
                _.createCanvas(this.width, this.height);

                this.setVariations(this.numVariations)

                this.step = this.width / this.referenceSize;
            };

            _.draw = () => {
                _.frameRate(5)
                _.background(255);

                _.line(0, this.height / 2, this.width, this.height / 2)

                _.translate(this.step / 2, this.height / 2)

                _.stroke("black");
                _.strokeWeight(4);

                _.noFill()
                _.strokeWeight(4)

                // Draw Series
                const drawSeries = (series, color) => {
                    _.push();
                    _.noFill();
                    _.strokeWeight(4);
                    _.stroke(color);
                    _.beginShape();
                    for (let i = 0; i < series.length; i++) {
                        _.vertex(i * this.step, -series[i] * this.scale)
                    }
                    _.endShape();
                    _.pop();
                }

                this.variations.forEach(variation => drawSeries(variation, _.color(0, 255, 255, 100)));

                if (this.showReference) {
                    drawSeries(this.reference, "blue")
                }

                if (!(this.center && this.center.length) || !this.showCenter) return;
                drawSeries(this.center, "red")
            };
        }

        new p5(sketch, this.interactionRef.nativeElement);
    }
}
