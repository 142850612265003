<div id="footer">
    <div class="progression">
        <app-course-progression [courseEntries]="courseEntries" [activeIndex]="activeIndex"
            (emitIndexChange)="updateItemIndex($event)"></app-course-progression>
    </div>
    <div class="navigation">
        <button [disabled]="activeIndex===0" (click)="goBack()">Zurück</button>
        <button [disabled]="activeIndex===courseEntries.length"
            (click)="goForth()">{{activeIndex!==courseEntries.length-1?"Weiter":"Abschließen"}}</button>
    </div>
</div>