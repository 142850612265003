<div id="course-step">
    <div class="section description">
        <div class="step">
            <div class="block">
                <div class="explanation">
                    <h3>Hier sieht man wie das Mittel von einigen Sinuskurven mit Hilfe von DBA ermittelt wird.</h3>
                    <h4>Man erkennt gut, dass sich die Mittellinie immer mehr der Referenz annähert, je höher die Kurvenanzahl ist.</h4>
                </div>
                <div class="input">
                    <div class="initialize">
                        <div class="inputs">
                            <div>
                                <label for="toggle-reference">Referenz umschalten</label>
                                <input type="checkbox" name="toggle-reference" id="toggle-reference"
                                    (change)="showReference = !showReference" [checked]="showReference">
                            </div>
                            <div>
                                <label for="toggle-center">DBA-Mittel umschalten</label>
                                <input type="checkbox" name="toggle-center" id="toggle-center"
                                    (change)="showCenter = !showCenter" [checked]="showCenter">
                            </div>
                            <div>
                                <label for="toggle-auto-change">Automatische Kurvenanzahl umschalten</label>
                                <input type="checkbox" name="toggle-auto-change" id="toggle-auto-change"
                                    (change)="toggleAutoChange()" #autoChangeCheckbox>
                            </div>
                            <div>
                                <label for="sample-size">Stichprobengröße</label>
                                <input type="range" name="sample-size" id="sample-size" min="2" max="100" step="1" (input)="setVariations($event)" #sampleSizeSlider>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="section canvas" >
        <div id="canvas" #interaction></div>
    </div>
</div>