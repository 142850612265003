export interface ItemEntry<T> {
    title: T,
    index?: number,
    additional?: string
}

export enum CourseSlideTitle {
    INTRODUCTION_DTW = "Einführung - DTW",
    IDEA_DTW = "Vorstellung - DTW",
    DEMO_DTW = "Demo - DTW",
    INTRODUCTION_DBA = "Einführung - DBA",
    IDEA_DBA = "Vorstellung - DBA",
    DEMO_DBA = "Demo - DBA",
    QUIZ = "Quiz"
}


export const courseEntries: ItemEntry<CourseSlideTitle>[] = Object.values(CourseSlideTitle).map((title, index) => ({title, index }));