import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'x-mark',
  templateUrl: './x-mark.component.html',
  styleUrls: ['./x-mark.component.scss']
})
export class XMarkComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
