<div id="course-step">
    <div class="description">
        <div class="course-section">
            <h1>Vorstellung DTW-Algorithmus</h1>
            <div class="split">
                <div class="segment wording">
                    <p>
                        Wir haben zwei Zeitserien A und B, diese können unterschiedlicher Länge sein. Hier sieht man
                        zuerst, wie diese beiden Zeitserien mit der euklidischen Distanz miteinander verglichen werden.
                        Die euklidischen Distanz berechnet sich durch die Summe der Teilabstände in jedem x-Wert. <br>
                        Hier also: 0 + 3 + 2 + 6 + 8 + 7 + 3 + 2 + 1 + 0 = <strong>32</strong>
                    </p>
                    <img class="fill" src="../../../../assets/graphics/euclidian.png" alt="">
                    <p>
                        Nun überprüfen wir wie groß die Distanz mit dem DTW-Algorithmus ist.
                    </p>
                </div>

                <div class="segment code">
                    <pre>
const seriesA = [1, 3, 4, 9, 8, 2, 1, 5, 7, 3];
const seriesB = [1, 6, 2, 3, 0, 9, 4, 3, 6, 3];
                    </pre>
                </div>
            </div>

            <app-scroll-hint></app-scroll-hint>
        </div>

        <div class="course-section">
            <div class="split">
                <div class="segment wording">
                    <div>
                        Der erste Schritt beim DTW-Algorithmus ist die Erstellung einer Matrix. Die einzelnen Punkte
                        der Matrix werden zunächst mit ∞ initialisiert. <br> Die Matix hat eine Größe von |A| x |B| mit
                        <br>
                        <div class="series">
                            <strong>
                                A = Zeitserie1 = [1,3,4,9,8,2,1,5,7,3]
                            </strong> und <br>
                            <strong>
                                B = Zeitserie2 = [1,6,2,3,0,9,4,3,6,3]
                            </strong>
                        </div>
                    </div>
                    <img src="../../../../assets/graphics/initialMatrix.png" alt="">
                </div>

                <div class="segment code">
                    <pre>
let matrix = [];
for (let i = 0; i &lt; sizeA; i++) {{"{"}}
    matrix[i] = [];
    pathMatrix[i] = [];
    for (let j = 0; j &lt; sizeB; j++) {{"{"}}
        matrix[i][j] = Number.POSITIVE_INFINITY
    }
}
                    </pre>
                </div>
            </div>
        </div>

        <div class="course-section">
            <div class="split">
                <div class="segment wording">
                    <p>
                        Im nächsten Schritt bestimmen wir schrittweise die Distanz der einzelnen Punkte. Dazu wird die
                        Distanz der beiden betrachteten Datenpunkte zur kleinsten Distanz der benachbarten Vorgänger
                        addiert.
                    </p>
                    <img src="../../../../assets/graphics/matrixFillFunction.png" alt="">
                    <img src="../../../../assets/graphics/matrixFillFunction_step_complete.png" alt="">

                </div>

                <div class="segment code">
                    <pre>
matrix[0][0] = distance(seriesA[0], seriesB[0]);

for (var x = 1; x &lt; sizeA; x++) {{"{"}}
    var cost = distance(seriesA[x], seriesB[0]);
    matrix[x][0] = cost + matrix[x - 1][0];
}

for (var y = 1; y &lt; sizeB; y++) {{"{"}}
    var cost = distance(seriesA[0], seriesB[y]);
    matrix[0][y] = cost + matrix[0][y - 1];
}

for (var x = 1; x &lt; sizeA; x++) {{"{"}}
    for (var y = 1; y &lt; sizeB; y++) {{"{"}}
        var cost = distance(seriesA[x], seriesB[y]);
        matrix[x][y] =
            cost + Math.min(
                matrix[x - 1][y], // Insertion
                matrix[x][y - 1], // Deletion
                matrix[x - 1][y - 1]); // Match
    }
}
                    </pre>
                </div>
            </div>
        </div>

        <div class="course-section">
            <div class="split">
                <div class="segment wording in-one">
                    <p>
                        Wenn man nun die gesamte Matrix gefüllt hat, steht im letzten Feld der Matrix die Distanz
                        dieser beiden Zeitreihen. Bei diesen beiden Zeitreihen liegt die mit DTW ermittelte Distanz bei
                        15. Somit ist die DTW-Distanz hier deutlich kleiner als die Euklidische Distanz.
                    </p>
                    <img class="fill" src="../../../../assets/graphics/filledMatrix.png" alt="">
                </div>

            </div>
        </div>


        <div class="course-section">
            <div class="split">
                <div class="segment wording">
                    <p>
                        Als nächstes wird der Warping-Pfad aufgebaut, dabei geht man mit Backtracking vom letzten Punkt
                        der Matrix zum ersten Punkt der Matrix, indem man immer den kleinsten Wert der drei Vorgänger
                        nimmt, bis man schließlich am Anfang ist.
                    </p>
                    <img src="../../../../assets/graphics/matrixFillFunction_path.png" alt="">
                    <img src="../../../../assets/graphics/matrixPathFunction_allInOne.png" alt="">
                </div>

                <div class="segment code">
                    <pre>
var cx = sizeA - 1;
var cy = sizeB - 1;
path.push({{"{"}} x: cx, y: cy });
while ((cx > 0) || (cy > 0)) {{"{"}}
    if ((cx > 0) && (cy > 0)) {{"{"}}
        var min = Math.min(
            matrix[cx - 1][cy], // Insertion
            matrix[cx][cy - 1], // Deletion
            matrix[cx - 1][cy - 1]); // Match
        if (min === matrix[cx - 1][cy - 1]) {{"{"}}
            cx--;
            cy--;
        } else if (min === matrix[cx - 1][cy]) {{"{"}}
            cx--;
        } else if (min === matrix[cx][cy - 1]) {{"{"}}
            cy--;
        }
    } else if ((cx > 0) && (cy === 0)) {{"{"}}
        cx--;
    } else if ((cx === 0) && (cy > 0)) {{"{"}}
        cy--;
    }
    path.push({{"{"}} x: cx, y: cy });
}
                    </pre>
                </div>
            </div>
        </div>


        <div class="course-section">
            <div class="split">
                <div class="segment wording ">
                    <p>
                        Im nächsten Schritt kann man die Verbindung der beiden Zeitreihen konstruieren. Hier schaut man
                        sich den oben erstellten Pfad an und verbindet die beiden Zeitreihen an diesen Punkten bzw.
                        zieht eine Linie von Punkt x in Zeitreihe A zu Punkt y in Zeitreihe B.
                    </p>
                    <img class="fill" src="../../../../assets/graphics/DTW.png" alt="">
                </div>
            </div>
        </div>

        <div class="course-section">
            <div class="split">
                <div class="segment wording">
                    <h4>
                        Hier wird der Code nochmal im Detail erklärt
                    </h4>

                    <div class="code">
                        <pre>
// Es werden 2 Zeitserien genommen, dargestellt als Arrays
const seriesA = [1, 3, 4, 9, 8, 2, 1, 5, 7, 3];
const seriesB = [1, 6, 2, 3, 0, 9, 4, 3, 6, 3];

// Wir erstellen zwei Hilfsvariablen um uns im Verlauf des
// Algorithmus Schreibaufwand zu sparen
const sizeA = seriesA.length;
const sizeB = seriesB.length;

// Es wird eine Matrix erstellt, dargestellt als 2D-Array
// und jede Zelle wird initial mit POSITIVE_INFINITY gefüllt
let matrix = [];
for (let i = 0; i &lt; sizeA; i++) {{"{"}}
    matrix[i] = [];
    pathMatrix[i] = [];
    for (let j = 0; j &lt; sizeB; j++) {{"{"}}
        matrix[i][j] = Number.POSITIVE_INFINITY
    }
}

// Der Matrixursprung bekommt die Distanz der ersten Werte der Serien
matrix[0][0] = distance(seriesA[0], seriesB[0]);

// Es werden die Kosten der ersten Spalte der Matrix berechnet
for (var x = 1; x &lt; sizeA; x++) {{"{"}}
    var cost = distance(seriesA[x], seriesB[0]);
    matrix[x][0] = cost + matrix[x - 1][0];
}

// Es werden die Kosten der ersten Reihe der Matrix berechnet
for (var y = 1; y &lt; sizeB; y++) {{"{"}}
    var cost = distance(seriesA[0], seriesB[y]);
    matrix[0][y] = cost + matrix[0][y - 1];
}

// Die Matrix wird befüllt indem man die Distanz zwischen jedem Element
// der Serien ermittelt und dazu die geringste Distanz zu den Vorgängern
// addiert.
for (var x = 1; x &lt; sizeA; x++) {{"{"}}
    for (var y = 1; y &lt; sizeB; y++) {{"{"}}
        var cost = distance(seriesA[x], seriesB[y]);
        matrix[x][y] =
            cost + Math.min(
                matrix[x - 1][y], // Insertion
                matrix[x][y - 1], // Deletion
                matrix[x - 1][y - 1]); // Match
    }
}

// Im nächsten Schritt wird der Pfad erstellt.
// Wir erstellen uns dazu zwei Variablen um unsere Aktuelle
// Position in der Matrix zu verfolgen.
// Angefangen wird im letzten Element der Matrix.
var cx = sizeA - 1;
var cy = sizeB - 1;

// Wir fügen diese Indizes zu unserem Pfad hinzu
path.push({{"{"}} x: cx, y: cy });

// Wir laufen so lange durch die Matrix durch,
// solange unsere Position in der Matrix nicht
// im Ursprung ist
while ((cx > 0) || (cy > 0)) {{"{"}}
    // Wir prüfen ob sich das aktuelle Element nicht am
    // Rand der Matrix befindet
    if ((cx > 0) && (cy > 0)) {{"{"}}
        // Wir ermitteln den Wert der Zelle mit dem geringsten
        // Wert
        var min = Math.min(
            matrix[cx - 1][cy], // Insertion
            matrix[cx][cy - 1], // Deletion
            matrix[cx - 1][cy - 1]); // Match

        if (min === matrix[cx - 1][cy - 1]) {{"{"}}
            // Wenn der geringste Wert aus der Zelle
            // matrix[cx - 1][cy - 1] ist dekrementieren
            // wir cx und cy;
            cx--;
            cy--;
        } else if (min === matrix[cx - 1][cy]) {{"{"}}
            // Wenn der geringste Wert aus der Zelle
            // matrix[cx - 1][cy] ist dekrementieren
            // wir cx;
            cx--;
        } else if (min === matrix[cx][cy - 1]) {{"{"}}
            // Wenn der geringste Wert aus der Zelle
            // matrix[cx][cy - 1] ist dekrementieren
            // wir cy;
            cy--;
        }
    } else if ((cx > 0) && (cy === 0)) {{"{"}}
        // Wenn sich das aktuelle Element am linken Rand
        // der Matrix befindet dekrementieren wir cx
        cx--;
    } else if ((cx === 0) && (cy > 0)) {{"{"}}
        // Wenn sich das aktuelle Element am oberen Rand
        // der Matrix befindet dekrementieren wir cy
        cy--;
    }

    // Wir fügen die aktuellen Indizes zu unserem Pfad hinzu
    path.push({{"{"}} x: cx, y: cy });
}
                    </pre>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>