<div id="course-step">
    <div class="description side-by-side">
        <div class="course-section description">
            <h2>Was ist DTW Barycenter Averaging?</h2>

            <p>
                DBA steht für DTW Barycenter Averaging und ist ein Algorithmus um eine Mittelwert-Zeitserie C‘ aus einer Menge von Zeitserien S zu bestimmen.
            </p>
            <p>
                Dabei wird die initiale Mittelwert-Zeitserie C iterativ verfeinert bis zum Schluss die Folge C‘ resultiert um dadurch die Summe der DTW-Distanzen, zu den Zeitreihen, bestmöglich zu minimieren. Die Summe setzt sich aus den einzelnen Distanzen der einzelnen
                Koordinaten der Mittelwert-Zeitserie zu den einzelnen Koordinaten aus der Zeitserienmenge zusammen.
            </p>
            <p>
                Um diese Koordinaten der Mittelwert-Zeitserie zu ermitteln, muss man aus den notwendigen Koordinaten der Zeitserienmenge zunächst das Baryzentrum ermitteln. Das Baryzentrum stellt dann den Schwerpunkt aus den Koordinaten der Zeitserienmenge dar. Sobald
                man dann alle Baryzentren berechnet hat, ergibt sich daraus die finale Mittelwert-Zeitserie.
            </p>
        </div>

        <div class="course-section image">
            <img src="../../../../assets/graphics/averaging.png" alt="Averaging using DBA">
        </div>
    </div>
</div>